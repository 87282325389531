<template>
    <div id="app">
        <a-modal
                :visible="show"
                title="管理员授权"
                :styles="styles"
                centered
                @ok="doSubmit"
                @cancel="_close">
            <Tree :data="rules" check-strictly empty-text="请稍后，数据加载中..." ref="tree" :check-directly="true" show-checkbox @on-check-change="setRules"></Tree>
            <div class="demo-drawer-footer">
                <a-button shape="circle" style="margin-right: 8px" @click="_close">取消</a-button>
                <a-button shape="circle" type="primary" @click="doSubmit">确认提交</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import request from "@/config/request";
    import utils from "@/common/utils";
    export default {
        data(){
            return{
                show:false,
                loading:false,
                styles: {
                    width:'300px',
                    top:'80px'
                },
                rules:[],
                formData:{
                    rules:[],
                    adminId:''
                }
            }
        },
        methods:{
            _show(adminId){
                this.formData.adminId = adminId;
                this.rules = [];
                this.getRules()
                this.show = true;
            },
            _close(){
                this.rules = [];
                this.show = false;
                this.$emit('close')
            },
            doSubmit(){
                // console.log(this.formData)
                this.http.post("/mch/admin/rule/save",this.formData).then(ret=>{
                    if(ret.code==200){
                        this.utils.success("授权成功!").then(()=>{
                            this._close();
                        })
                    }
                })
            },
            getRules(){
                this.loading =true;
                request.get("/mch/admin/rule/"+this.formData.adminId).then(ret=>{
                    this.loading = false;
                    if(ret.code==200){
                        // console.log(ret.data)
                        this.rules = ret.data;
                    }
                }).catch(err=>{
                    this.loading = false;
                    utils.closeAll();
                })
            },
            setRules(){
                let res = this.$refs.tree.getCheckedAndIndeterminateNodes();
                let ids = [];
                res.forEach((item,index)=>{
                    ids.push(item.menuId)
                })
                this.formData.rules = ids;
            }
        }
    }
</script>

<style scoped>
    .demo-drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;
    }
</style>
